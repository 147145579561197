<template>
  <CrmFormLayout title="合同详情">
    <div class="mali-cusframework-form detail-contract-add-page view-detail-contract-page">
      <el-form ref="form" label-position="top" :show-message="true" :rules="formType != 3 ? rules : {}" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="80">
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>合同编号</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="contractNo">
                    <el-input
                      v-model="formData.contractNo"
                      :maxlength="50"
                      clearable
                      placeholder="请输入合同编号"
                      :disabled="readOnly || (status === 2 && formType !== 1)"
                      class="mali-full__input"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>签约时间</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="signDate ">
                    <el-date-picker
                      v-if="formData.signDate || !readOnly"
                      v-model="formData.signDate"
                      type="date"
                      prefix-icon="null"
                      placeholder="请选择签约时间"
                      :disabled="readOnly"
                      :editable="false"
                      class="mali-full__input"
                    ></el-date-picker>
                    <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>客&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="customerId">
                    <!-- <el-input
              v-model="contractName"
              class="mali-full__input"
              :disabled="true"
            >
                  </el-input>-->
                    <el-tooltip v-if="String(contractName).length > 27" class="item_uaser" effect="dark" :content="contractName" placement="top-start">
                      <div class="doc-name-item" style="width: 306px">
                        {{ contractName }}
                      </div>
                    </el-tooltip>
                    <div v-else class="doc-name-item" style="width: 306px">
                      {{ contractName }}
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>商&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;机</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="businessId">
                    <!-- <el-input
              v-model="businessName"
              class="mali-full__input"
              :disabled="true"
            >
                  </el-input>-->
                    <el-tooltip v-if="String(businessName).length > 27" class="item_uaser" effect="dark" :content="businessName" placement="top-start">
                      <div class="doc-name-item">{{ businessName }}</div>
                    </el-tooltip>
                    <div v-else class="doc-name-item">{{ businessName }}</div>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>生效时间</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="startDate ">
                    <el-date-picker
                      v-model="formData.startDate"
                      v-if="formData.startDate || !readOnly"
                      type="date"
                      prefix-icon="null"
                      placeholder="请选择生效时间"
                      :disabled="readOnly"
                      :editable="false"
                      class="mali-full__input"
                    ></el-date-picker>
                    <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>到期时间</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="endDate ">
                    <el-date-picker
                      v-model="formData.endDate"
                      v-if="!readOnly || formData.endDate"
                      type="date"
                      prefix-icon="null"
                      placeholder="请选择到期时间"
                      :disabled="readOnly"
                      :editable="false"
                      class="mali-full__input"
                    ></el-date-picker>
                    <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>合同金额(元)</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="amount">
                    <el-input
                      v-model="formData.amount"
                      @blur="getAmount($event, 'amount')"
                      :maxlength="12"
                      clearable
                      placeholder="请输入合同金额(元)"
                      :disabled="readOnly"
                      class="mali-full__input"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>审核状态</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="amount">
                    <el-input
                      v-model="formData.auditStatusDesc"
                      @blur="getAmount($event, 'amount')"
                      :maxlength="12"
                      clearable
                      :disabled="readOnly"
                      class="mali-full__input"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>是否已双签</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="isCounterSigned">
                    <el-select
                      v-model="formData.isCounterSigned"
                      :automatic-dropdown="true"
                      class="mali-full__input"
                      ref="isCounterSigned"
                      placeholder="请选择"
                      :class="readOnly ? 'mali-full__Arrow_clo' : ''"
                      :disabled="readOnly"
                    >
                      <el-option key="true" label="是" :value="true"></el-option>
                      <el-option key="2" label="否" :value="false"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="contract_body">
                <div class="contract_body_title">
                  <span>客户指定汇率</span>
                </div>
                <div class="contract_body_main">
                  <el-form-item label prop="amount">
                    <el-input v-model="formData.prescribedRate" :maxlength="12" clearable :disabled="readOnly" class="mali-full__input"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-form-item label prop required>
          <div class="upload-box" style="margin-top: 20px">
            <div class="uploadTitle">
              <h1>
                {{ formType > 2 ? '合同文件' : '选择合同文件' }}
                <i class="star" v-if="formType < 3">*</i>
              </h1>
            </div>
            <FileContent
              :readOnly="readOnly"
              :fromType="formType"
              :fileType="fileType"
              :queryNum="0"
              :maxImg="1"
              :typeImg="1"
              :fileList="fileUrlsArray"
              @getFileUrls="getFileUrls"
              @isMassge="isMassge"
              style="margin-left: 10px"
            ></FileContent>
          </div>
        </el-form-item>

        <div class="maili-edit__form label-con" style="margin-top: 20px">
          <el-form-item :label="formType > 3 ? '备注' : '备注'" prop="remark">
            <el-input v-model="formData.remark" :maxlength="1000" :disabled="readOnly" class="mali-full__input" type="textarea" resize="none"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="mail-form__detail" v-if="formType === 3">
        创建人：{{ formData.creator }} &nbsp;&nbsp;创建时间：{{ formData.createdTime }}&nbsp;&nbsp; 更新人：{{
          formData.lastEditOperator
        }}
        &nbsp;&nbsp;更新时间：{{ formData.lastEditTime }}
      </div>
      <div class="crm-button__group" v-if="formType >= 3">
        <button class="crm-form__button primary" @click="close(false)">关闭</button>
      </div>
    </div>
  </CrmFormLayout>
</template>
<script>
import FileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: { FileContent },
  props: {
    customData: [Object],
    data: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
    formType: {
      // 表单操作类型 1 新增 2 修改
      type: [String, Number],
      default: 1,
    },
  },
  computed: {
    readOnly() {
      return this.formType > 2;
    },
  },
  data() {
    return {
      sapcedata: '-',
      businessName: this.customData.name,
      contractName: this.customData.customerName,
      titleImage: '',
      fileNameArr: [],
      successDialogData: {
        dialogVisible: false,
        title: '上传双签合同成功后，商机状态将更新为客户已签约，确定要上传吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      },
      fileUrlArr: [],
      fileUrlsArray: [],
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt,.xls,.xlsx,.zip,.PDF,.DOC,.DOCX,.JPG,.PNG,.GIF,.TXT,.XLS,.XLSX,.ZIP',
      formData: {
        allSubordinates: null,
        amount: '',
        attachmentDto: {
          eventId: this.eventId,
          id: '',
          name: '',
          operateType: 1,
          source: 3,
          url: '',
        },
        operateType: this.formType,
        businessId: this.eventId,
        contractNo: '',
        customerId: this.customData.customerId,
        endDate: '',
        remark: '',
        signDate: '',
        startDate: '',
        isCounterSigned: false,
      },
      disabled: false,
      rules: {
        contractNo: [
          {
            required: true,
            message: '请输入合同编号',
            trigger: 'blur',
          },
        ],
        amount: [
          {
            pattern: /\d{0,9}(\.\d{0,2})?/,
            message: '请输入合法数字',
            trigger: 'blur',
          },
        ],
        customerId: [
          {
            required: true,
            message: '请输入客户',
            trigger: 'blur',
          },
        ],
        businessId: [
          {
            required: true,
            message: '请选择商机',
            trigger: 'blur',
          },
        ],
        signDate: [
          {
            required: true,
            message: '请选择签约时间',
            trigger: 'blur',
          },
        ],
        'attachmentDto.url': [
          {
            // required: true, message: '请选择合同文件', trigger: 'blur'
          },
        ],
      },
    };
  },
  methods: {
    getAmount(e, prop) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) == '0') {
          // eslint-disable-line

          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      this.formData[prop] = value;
    },
    getDetails(id) {
      this.ajax({
        type: 'GET',
        url: `malicrm/contract/contractDetail/${id}`,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            const data = res.result;
            this.businessName = data.businessName;
            this.contractName = data.customerName;
            this.formData.id = id;
            this.formData = Object.assign(this.formData, data);
            this.formData.attachmentDto.name = data.fileName;
            this.formData.attachmentDto.url = data.fileUrl;
            if (this.formType === 3 && this.formData.amount) {
              this.formData.amount = this.formData.amount.toLocaleString();
            }
            this.fileUrlsArray = [
              {
                name: data.fileName,
                url: data.fileUrl,
              },
            ];
            if (!this.formData.remark && this.formType === 3) {
              this.formData.remark = '-';
            }
            if (!this.formData.amount && this.formType === 3) {
              this.formData.amount = '-';
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getFileUrls(titleImg, fileNameArray, fileUrlArray) {
      this.formData.attachmentDto.name = fileNameArray[0];
      this.formData.attachmentDto.url = fileUrlArray[0];
      this.$refs.form.validateField(['attachmentDto.url']);
      this.titleImage = titleImg;
      this.fileNameArr = fileNameArray;
      this.fileUrlArr = fileUrlArray;
      this.disabled = false;
    },
    isMassge() {
      // 点击添加文件时禁用保存按钮
      this.disabled = true;
    },
    close() {
      this.$emit('close', false);
    },
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.attachmentDto.url && this.formData.attachmentDto.url !== '') {
            if (this.formData.isCounterSigned) {
              this.successDialogData.dialogVisible = true;
            } else {
              this.submitForm();
            }
          } else {
            this.errorTip('请选择合同文件');
            this.disabled = true; // 防止保存按钮重复点击
            setTimeout(() => {
              this.disabled = false;
            }, 3000);
          }
        } else {
          return false;
        }
      });
    },
    submitForm() {
      // 提交表单
      const params = Object.assign({}, this.formData);
      this.ajax({
        type: 'POST',
        url: 'malicrm/contract/operateContract',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          setTimeout(() => {
            this.$pcNProgress.done();
          }, 300);
          if (res.code === 200) {
            if (this.formType === 1) {
              this.successTip('添加成功', 3000);
            } else {
              this.successTip('编辑成功', 3000);
            }
            this.$emit('cancel', true);
            this.successDialogData.dialogVisible = false;
          } else {
            this.errorTip(res.message);
            this.successDialogData.dialogVisible = false;
          }
        },
      });
    },
    cancel() {
      // 取消操作
      this.$emit('cancel', false);
    },
  },
  created() {
    if (this.formType > 1) {
      // 修改就回填数据过来
      this.getDetails(this.data.id);
    } else {
      this.formData.source = 3;
    }
  },
};
</script>

<style lang="scss" scoped>
.doc-name-item {
  height: 40px;
  line-height: 40px;
  /*background: #f5f7fa;*/
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  color: #1a1a1a;
}
</style>
