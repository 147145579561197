import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_list = _resolveComponent("crm-list")!
  const _component_OrderDocForm = _resolveComponent("OrderDocForm")!
  const _component_ViewOrderDocForm = _resolveComponent("ViewOrderDocForm")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_crm_list, {
      url: "/malicrm/contract/contractList",
      filterOption: _ctx.filterOptions,
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "DocumentsList",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshTable(false)))
        }, "刷新")
      ]),
      _: 1
    }, 8, ["filterOption", "columOptions", "onRowClick"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible,
      erp: "",
      onClose: _ctx.closeVisible
    }, {
      default: _withCtx(() => [
        (_ctx.formType === 1 || _ctx.fromType === 2)
          ? (_openBlock(), _createBlock(_component_OrderDocForm, {
              key: 0,
              formType: _ctx.formType,
              data: _ctx.rowData,
              customData: _ctx.rowData,
              onClose: _ctx.closeVisible
            }, null, 8, ["formType", "data", "customData", "onClose"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 3)
          ? (_openBlock(), _createBlock(_component_ViewOrderDocForm, {
              key: 1,
              formType: _ctx.formType,
              data: _ctx.rowData,
              "custom-data": _ctx.rowData,
              onClose: _ctx.closeVisible
            }, null, 8, ["formType", "data", "custom-data", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}