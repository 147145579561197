import { reactive, ref, toRefs } from 'vue';
type FormData = {
  allSubordinates: null;
  amount: number | string;
  attachmentDto: Record<string, any> | null;
  operateType: number | string;
  auditStatus: number | string;
  businessId: number | string;
  prescribedRate: number | string;
  contractNo: number | string;
  customerId: number | string;
  endDate: number | string;
  remark: number | string;
  signDate: number | string;
  startDate: number | string;
  isCounterSigned: boolean;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    allSubordinates: null,
    amount: '',
    attachmentDto: {
      eventId: params.eventId,
      id: '',
      name: '',
      operateType: 1,
      source: 3,
      url: '',
    },
    auditStatus: 0,
    operateType: params.formType,
    businessId: params.eventId,
    contractNo: params.customData?.businessContractNo,
    customerId: params.customData?.customerId,
    prescribedRate: '',
    endDate: '',
    remark: '',
    signDate: '',
    startDate: '',
    isCounterSigned: false,
    ...params,
  });
  const getAmount = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两个小数
    value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) == '0') {    // eslint-disable-line

        value = value.substr(1);
      }
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    formData[prop] = value;
  };
  const clearType = () => {
    formRef.value.clearValidate();
  };
  const formatRate = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两个小数
    value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) === 0) {
        value = value.substr(1);
      }
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    if (value >= 100) {
      const index = value.indexOf('.');
      if (index > -1) {
        const intStr = value.substr(0, index - 1);
        const dotStr = value.substr(index);
        value = intStr + dotStr;
      } else {
        value = value.substr(0, 2);
      }
    }
    if (Number(value) === 0) {
      formData[prop] = null;
    } else {
      formData[prop] = value;
    }
  };
  const customFormData = reactive({
    fileUrlsArray: [],
    fileType:
      '.pdf, .doc, .docx, .jpg, .png, .gif, .txt,.xls,.xlsx,.zip,.PDF,.DOC,.DOCX,.JPG,.PNG,.GIF,.TXT,.XLS,.XLSX,.ZIP',
    fileTips:
      '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx,zip   大小上限：30M',
  });
  const rules = reactive({
    contractNo: [
      {
        required: true,
        message: '请输入合同编号',
        trigger: 'blur',
      },
    ],
    amount: [
      {
        pattern: /\d{0,9}(\.\d{0,2})?/,
        message: '请输入合法数字',
        trigger: 'blur',
      },
    ],
    prescribedRate: [],
    customerId: [
      {
        required: true,
        message: '请输入客户',
        trigger: ['blur', 'change'],
      },
    ],
    businessId: [
      {
        required: true,
        message: '请选择商机',
        trigger: ['blur', 'change'],
      },
    ],
    auditStatus: [
      {
        required: true,
        message: '请选择审核状态',
        trigger: ['blur', 'change'],
      },
    ],
    signDate: [
      {
        required: true,
        message: '请选择签约时间',
        trigger: ['change', 'blur'],
      },
    ],
    'attachmentDto.url': [
      { required: true, message: '请上传合同文件', trigger: 'change' },
    ],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    getAmount,
    clearType,
    formatRate,
  };
};
