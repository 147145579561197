
import { httpGet, httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import {
  ajaxLoading,
  erpConfirm,
  errorMessage,
  formatTime,
  setTitleLabel,
  successMessage,
} from '@/utils/publicMethods';
import { defineComponent, ref, computed } from 'vue';
import useFormData from './useOrderDocFormData';
export default defineComponent({
  components: { FileContent },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    customData: [Object],
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
    canTransToOrder: {
      type: [Boolean],
      default: true,
    },
    status: {
      // 商机状态
      type: [String, Number],
      default: '',
    },
    eventId: {
      // 任务类型(来源)
      type: [String, Number],
      default: 3,
    },
    source: {
      // 任务类型(来源)
      type: [String, Number],
      default: 3,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const sapcedata = ref('-');
    let params: any = {};
    if (props.formType > 1) {
      params = Object.assign({}, props.customData);
    }
    const {
      rules,
      formData,
      formRef,
      setFormData,
      fileUrlsArray,
      getAmount,
      clearType,
      formatRate,
    } = useFormData(params);
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      if (fileList.length > 0 && formData.attachmentDto) {
        formData.attachmentDto.name = fileList[0].name;
        formData.attachmentDto.url = fileList[0].url;
      } else {
        if (formData.attachmentDto) {
          formData.attachmentDto.name = null;
          formData.attachmentDto.url = null;
        }
      }
      formRef.value.validate('attachmentDto.url');
    };
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const initFile = () => {
      if (formData.fileName && formData.fileUrl) {
        (fileUrlsArray.value as any).push({
          name: formData.fileName,
          url: formData.fileUrl,
        });
        if (formData.attachmentDto) {
          formData.attachmentDto.name = formData.fileName;
          formData.attachmentDto.url = formData.fileUrl;
        }
      }
    };
    const submitData = async () => {
      formData.endDate = formatTime(formData.endDate, 'YYYY-MM-DD') as any;
      formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD') as any;
      formData.startDate = formatTime(formData.startDate, 'YYYY-MM-DD') as any;
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('malicrm/contract/operateContract', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        if (props.formType === 1) {
          successMessage('添加成功');
        } else {
          successMessage('编辑成功');
        }
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          if (formData.isCounterSigned) {
            erpConfirm(
              '上传双签合同成功后，商机状态将更新为客户已签约，确定要上传吗？'
            ).then(() => {
              submitData();
            });
          } else {
            submitData();
          }
        } else {
          return false;
        }
      });
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`malicrm/contract/contractDetail/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        initFile();
      } else {
        errorMessage(res.message);
      }
    };
    if (props.formType > 1) {
      initData((props.data as any).id);
      // const feeObj = JSON.parse(JSON.stringify(props.data));
      // setFormData(feeObj.value);
    }
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      sapcedata,
      getFileUrls,
      fileUrlsArray,
      initFile,
      initData,
      readOnly,
      getAmount,
      clearType,
      formatRate,
    };
  },
});
