import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { errorMessage, loadPage, showFiles } from '@/utils/publicMethods';
import { httpGet } from '@/api';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'contractNo',
      value: '',
      width: 180,
      placeholder: '请输入订单合同编号',
    },
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入所属客户',
    },
    {
      type: 'input',
      prop: 'businessName',
      value: '',
      placeholder: '请输入所属商机',
    },
    {
      type: 'input',
      prop: 'fileName',
      value: '',
      placeholder: '请输入文件名',
    },
    {
      type: 'time',
      prop: ['startDate', 'endDate'],
      value: [],
      placeholder: ['到期开始时间', '到期结束时间'],
    },
    {
      type: 'input',
      prop: 'creator',
      value: '',
      placeholder: '请输入创建人',
    },
    {
      type: 'time',
      prop: ['startTime', 'endTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
    {
      type: 'time',
      prop: ['updateStartDate', 'updateEndDate'],
      value: [],
      placeholder: ['更新开始日期', '更新结束日期'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '订单合同编号',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.contractNo}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const clickFn = (row) => {
            Instance.setupState.setRowData(row);
            Instance.setupState.setFormType(3);
            Instance.setupState.showVisible();
          };
          return { clickFn };
        },
      }),
      prop: 'contractNo',
      width: 160,
    },
    {
      label: '所属客户',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.customerName}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 所属客户 */
          const clickFn = async (row) => {
            // Instance.setupState.setRowData(row);
            // Instance.setupState.setFormType(2);
            // Instance.setupState.showVisible();
            // TODO 返回当前页面
            const res = await httpGet(
              `malicrm/customer/customerDetail/${row.customerId}`
            );
            if (res.code === 200) {
              let type = 0;
              if (row.customerIsDeleted) {
                type = 3;
              } else {
                if (row.isInPool) {
                  type = 2;
                } else {
                  type = 1;
                }
              }
              loadPage('ContactDetails', {
                id: row.customerId,
                isRecycle: false,
                type, // 1客户 2客户池 3回收站
                backFrom: '/documents/documentsList',
              });
            } else {
              errorMessage(res.message);
            }
          };
          return { clickFn };
        },
      }),
      prop: 'customerName',
      minWidth: 140,
    },
    {
      label: '所属商机',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.businessName}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 所属商机 */
          const clickFn = (row) => {
            console.log('row', row);
            // Instance.setupState.setRowData(row);
            // Instance.setupState.setFormType(2);
            // Instance.setupState.showVisible();
            let type = '';
            if (row.businessIsDeleted) {
              type = 'true';
            } else {
              type = 'false';
            }
            // TODO
            loadPage('OpportunityDetail', {
              id: row.businessId,
              eventId: row.businessId,
              isRecycle: type,
              backFrom: '/documents/documentsList',
            });
          };
          return { clickFn };
        },
      }),
      prop: 'businessName',
      minWidth: 140,
    },
    {
      label: '文件名',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.fileName}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 所属商机 */
          const clickFn = (row) => {
            // Instance.setupState.setRowData(row);
            // Instance.setupState.setFormType(2);
            // Instance.setupState.showVisible();
            showFiles(row.fileName, row.fileUrl);
          };
          return { clickFn };
        },
      }),
      prop: 'fileName',
      minWidth: 140,
    },
    {
      label: '到期时间',
      prop: 'endDate',
      component: defineComponent({
        template: `
          <div  :style="getFontColor(row)">
          {{filterName(row.endDate)}} 
          </div>
        `,
        props: ['row'],
        setup() {
          const getFontColor = (row) => {
            const res = '';
            if (row && row.isChangeColor === true) {
              return 'color:#E93939';
            }
            return res;
          };
          return { getFontColor };
        },
      }),
      width: 130,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 80,
      width: 130,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 180,
    },
    {
      label: '更新时间',
      prop: 'lastEditTime',
      width: 180,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button type="primary" plain @click="clickFn(row)">下载</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const clickFn = (row) => {
            showFiles(row.fileName, row.fileUrl, false);
          };
          return { clickFn };
        },
      }),
      prop: '',
      width: 120,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
